<template>
    <div>
        <div class="wrap" id="wrap">
            <ul class="content"></ul>
            <a href="javascript:;" class="prev">&#60;</a>
            <a href="javascript:;" class="next">&#62;</a>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        getStyle(obj,attr){
            return obj.currentStyle ? obj.currentStyle[attr] : window.getComputedStyle(obj,null)[attr];
        },
        animate(obj,json,fn){
	clearInterval(obj.timer);
	obj.timer=setInterval(function(){
		var bool=true;
		for(var k in json){
			var leader;
			if (k=='opacity') {
				if (this.getStyle(obj,k)==undefined) {
					leader=100;
				}else {
					leader=parseInt(this.getStyle(obj,k)*100);
				}
			}else {
				leader=parseInt(this.getStyle(obj,k)) || 0;
			}
			var step=(json[k]-leader)/10;
			step=step>0?Math.ceil(step):Math.floor(step);
			leader=leader+step;
			if(k=='zIndex'){
				obj.style[k]=json[k];
			}else if(k=='opacity'){
				obj.style[k]=leader/100;
				obj.style.filter='alpha(opacity='+leader+')';
			}else {
				obj.style[k]=leader+'px';
			}
			if(json[k]!=leader){
				bool=false;
			}
		}
		if(bool){
			clearInterval(obj.timer);
			if (fn) {
				fn();
			}
		}
	},10);
}
    },
    mounted(){
    var imgArr=[
        {"path":"../assets/images1/1.jpg"},
        {"path":"../assets/images1/2.jpg"},
        {"path":"../assets/images1/3.jpg"},
        {"path":"../assets/images1/4.jpg"},
        {"path":"../assets/images1/5.jpg"},
        {"path":"../assets/images1/6.jpg"},
        {"path":"../assets/images1/7.jpg"}
    ];
    var size=[
        {"top":60,"left":0,"width":400,"height":240,"zIndex":1,"opacity":0},
        {"top":60,"left":0,"width":400,"height":240,"zIndex":2,"opacity":40},
        {"top":30,"left":150,"width":500,"height":300,"zIndex":3,"opacity":70},
        {"top":0,"left":300,"width":600,"height":360,"zIndex":4,"opacity":100},
        {"top":30,"left":550,"width":500,"height":300,"zIndex":3,"opacity":70},
        {"top":60,"left":800,"width":400,"height":240,"zIndex":2,"opacity":40},
        {"top":60,"left":800,"width":400,"height":240,"zIndex":1,"opacity":0}
    ];
    var imgSum=imgArr.length;
    var wrap=document.getElementById('wrap');
    var cont=wrap.firstElementChild || wrap.firstChild;
    var btnArr=wrap.getElementsByTagName('a');
    var falg=true;
    var speed=7000;
    wrap.onmouseover=function(){
        for (var i=0;i<btnArr.length;i++) {
            btnArr[i].style.display='block';
        }
        clearInterval(wrap.timer);
    }
    wrap.onmouseout=function(){
        for (var i=0;i<btnArr.length;i++) {
            btnArr[i].style.display='none';
        }
        wrap.timer=setInterval(function(){
             move(true);
         },speed);
    }
    for (var i=0;i<imgSum;i++) {
        var lis=document.createElement('li');             
        // lis.style.cssText='top:'+size[i].top+'px;'+'left:'+size[i].left+'px;'+'width:'+size[i].width+'px;'+'z-index:'+size[i].zIndex+';'+'height:'
        // +size[i].height+'px;'+'opacity:'+size[i].opacity+';';
        lis.style.backgroundImage='url('+imgArr[i].path+')';
        cont.appendChild(lis);
    }
    var liArr=cont.children;
    move();
    wrap.timer=setInterval(function(){
        move(true);
    },speed);
    btnArr[1].onclick=function(){
        if (falg) {
            move(true);
        }
    }
    btnArr[0].onclick=function(){
        if (falg) {
            move(false);
        }
    }
    function move(bool){
        if(bool!=undefined){
            if(bool){
                size.unshift(size.pop());
            }else {
                size.push(size.shift());
            }
        }
        for (var i=0;i<liArr.length;i++) {
            this.animate(liArr[i],size[i],function(){
                falg=true;
            });
        }
    }
    }
}
</script>
<style>
body,ul {
	padding: 0;
	margin: 0;
	
}
ul {
	list-style: none;
}
.wrap {
	position: relative;
	width: 1200px;
	height: 360px;
	margin: 100px auto;
}
.content {
	position: absolute;
	width: 1200px;
	height: 360px;
}
.content li{
	position: absolute;
	background-size: 100% 100%;
	cursor: pointer;
}
.wrap a {
	position: absolute;
	display: none;
	z-index: 2;
	top: 50%;
	width: 60px;
	height: 60px;
	margin-top: -30px;
	font: 36px/60px "宋体";
	text-align: center;
	text-decoration: none;
	color: #fff;
	background: rgb(255, 100, 0);
	background: rgba(255, 100, 0, .6);
	transition: background 1s ease;
}
.wrap a:hover {
	background: rgb(255, 100, 0);
}
.prev {
	left: 30px;
}
.next {
	right: 30px;
}
</style>